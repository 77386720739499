@import 'tailwind';
@import 'fonts';
@import 'base';

@import '~nprogress/nprogress.css';

#nprogress {
  .bar {
    background-color: #F97E35;

    .peg {
      box-shadow: 0 0 10px #F97E35, 0 0 5px #F97E35;
    }
  }
}
